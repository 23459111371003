function initTrainingIntensityDistributionFullChart(
	mainActivitiesWeekTid, mainActivitiesMonthTid, allActivitiesWeekTid, allActivitiesMonthTid, datasetDimensions
	) {
	var trainingIntensityDistributionFull = echarts.init(document.getElementById('tid-full-chart'), null, {
		renderer: 'svg',
	})

	/** @type EChartsOption */
	var option = {	
		aria: {
			enabled: true
		},

		legend: {
			show: true,
			orient: "horizontal",
			itemWidth: 15,
			itemHeight: 8,
			textStyle: {
				color: "#7E8287",
				fontFamily: "Nunito",
				fontSize: 11,
			},
		},

		tooltip: {
			textStyle: {
				fontFamily: 'Nunito',
			},
			backgroundColor: false,
			padding: 0,
			formatter: function(params) {
				return '<div class="d-flex flex-column echart-tooltip text-11 text-secondary rounded-2 px-2 py-0">' + 
							'<div class="d-flex flex-column">' + 
								'<span class="fw-bold text-z1" >' + 
										'Zona 1: ' + params.value['zone 1'] +
								'</span>' +
								
								'<span class="fw-bold text-z2" >' + 
										'Zona 2: ' + params.value['zone 2'] +
								'</span>' +
								'<span class="fw-bold text-z4" >' + 
										'Zona 3: ' + params.value['zone 3'] +
								'</span>' +
								'<span class="fw-bold" >' + 
										'PI: ' + params.value['PI'] +
								'</span>' +
							'</div>' +
						'</div>';
			}
		},

		dataset: [
			{
				dimensions: datasetDimensions,
				source: mainActivitiesWeekTid,
			},{
				dimensions: datasetDimensions,
				source: allActivitiesWeekTid,
			},{
				dimensions: datasetDimensions,
				source: mainActivitiesMonthTid,
			},{
				dimensions: datasetDimensions,
				source: allActivitiesMonthTid,
			}
		],
		
		grid: [
			{
				top: '8%',
				height: '35%',
				right: 50,
				left: 50,
			},
			{
				top: '54%', 
				height: '38%',
				right: 50,
				left: 50,
			},
		],
		
		xAxis: [
			{ 
				name: 'Semanas',
				type: 'category',
				nameGap: 10,
				nameLocation: 'middle',
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					show: false,
				}
			},{ 
				name: 'Meses',
				type: 'category',
				gridIndex: 1,
				nameGap: 10,
				nameLocation: 'middle',
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					show: false,
				}
			}
		],
		
		yAxis: [
			{
				name: 'Distribuição da Intensidade do Treinamento (%)',
				nameLocation: 'start',
				type: 'value',
				nameGap: 10,
				nameTextStyle: {
					fontSize: 11,
					fontFamily: 'Nunito',
					color: '#7E8287',
					padding: [0, 0, 0, 180]
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				max: 100,
			},{
				type: 'value',
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				max: 100,
				gridIndex: 1,
			}, {
				name: 'Índice de Polarização (PI)',
				nameLocation: 'start',
				type: 'value',
				nameGap: 10,
				nameTextStyle: {
					fontSize: 11,
					fontFamily: 'Nunito',
					color: '#7E8287',
					padding: [0, 80, 0, 0]
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				gridIndex: 0,
				max: 4
			}, {
				type: 'value',
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				gridIndex: 1,
				max: 4
			}
		],

		series: [
			{ 
				type: 'bar',
				name: 'Zona 1',
				stack: 'week',
				barGap: '30%',
				barWidth: "50%",
				itemStyle: {
					color: '#38f746',
				},
				datasetIndex: 0,
			},{ 
				type: 'bar', 
				name: 'Zona 2',
				stack: 'week',
				itemStyle: {
					color: '#e8fe4b',
				},
				datasetIndex: 0,
				z: 2,
			},{ 
				type: 'bar', 
				name: 'Zona 3',
				stack: 'week',
				itemStyle: {
					color: '#fe3a1a',
				},
				datasetIndex: 0,
				z: 2,
			}, {
				type: 'line',
				symbol: 'none',
				lineStyle: {
					width: 1.5,
					color: "#555",
				},
				itemStyle: {
					color: '#555',
				},
				markLine: {
					data: [{
						yAxis: 2,
					}],
					label: {
						show: false
					},
					emphasis: {
						disabled: true
					},
					symbol: 'none',
					lineStyle: {
						type: 'dotted',
						width: 1,
						color: "#777",
					},
				},
				datasetIndex: 0,
				yAxisIndex: 2,
				z: 2,
			},

			// area chart to all activity weeks
			{ 
				type: 'line',
				name: 'Zona 1',
				stack: 'allWeek',
				lineStyle: {
					color: '#38f746',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#6af975',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 1,
				z: 1,
			},{ 
				type: 'line', 
				name: 'Zona 2',
				stack: 'allWeek',
				lineStyle: {
					color: '#e8fe4b',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#f4ffa5',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 1,
				z: 1,
			},{ 
				type: 'line', 
				name: 'Zona 3',
				stack: 'allWeek',
				lineStyle: {
					color: '#fe3a1a',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#ff9d8d',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 1,
				z: 1,
			}, {
				type: 'line',
				symbol: 'none',
				lineStyle: {
					width: 1,
					color: "#666",
					type: 'dashed',
				},
				itemStyle: {
					color: '#666',
				},
				datasetIndex: 1,
				yAxisIndex: 2,
			},

			// bar chart to main activity months
			{ 
				type: 'bar',
				name: 'Zona 1',
				stack: 'month',
				barCategoryGap: '30%',
				barWidth: '70%',
				itemStyle: {
					color: '#38f746',
				},
				label: {
					show: true,
					position: 'inside',
					formatter: function (params) {
						if (params.value['zone 1'] == 0) {
							return '';
						} else {
							return params.value['zone 1'] + '%';
						}
					},
					fontSize: 8,
					fontFamily: 'Nunito',
				},
				datasetIndex: 2,
				xAxisIndex: 1,
				yAxisIndex: 1,
			}, { 
				type: 'bar', 
				name: 'Zona 2',
				stack: 'month',
				itemStyle: {
					color: '#e8fe4b',
				},
				label: {
					show: true,
					position: 'inside',
					formatter: function (params) {
						if (params.value['zone 2'] == 0) {
							return '';
						} else {
							return params.value['zone 2'] + '%';
						}
					},
					fontSize: 8,
					fontFamily: 'Nunito',
				},
				datasetIndex: 2,
				xAxisIndex: 1,
				yAxisIndex: 1,
			}, { 
				type: 'bar', 
				name: 'Zona 3',
				stack: 'month',
				itemStyle: {
					color: '#fe3a1a',
				},
				label: {
					show: true,
					position: 'inside',
					formatter: function (params) {
						if (params.value['zone 3'] == 0) {
							return '';
						} else {
							return params.value['zone 3'] + '%';
						}
					},
					fontSize: 8,
					fontFamily: 'Nunito',
				},
				datasetIndex: 2,
				xAxisIndex: 1,
				yAxisIndex: 1,
			}, {
				type: 'line',
				symbol: 'none',
				lineStyle: {
					width: 1.5,
					color: "#555",
				},
				itemStyle: {
					color: '#555',
				},
				markLine: {
					data: [{
						yAxis: 2,
					}],
					label: {
						show: false
					},
					emphasis: {
						disabled: true
					},
					symbol: 'none',
					lineStyle: {
						type: 'dotted',
						width: 1,
						color: "#777",
					},
				},
				datasetIndex: 2,
				xAxisIndex: 1,
				yAxisIndex: 3,
			},

			// area chart to all activity months
			{ 
				type: 'line',
				name: 'Zona 1',
				stack: 'allMonths',
				lineStyle: {
					color: '#38f746',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#6af975',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 3,
				xAxisIndex: 1,
				yAxisIndex: 1,
				z: 1,
			},{ 
				type: 'line', 
				name: 'Zona 2',
				stack: 'allMonths',
				lineStyle: {
					color: '#e8fe4b',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#e8fe4b',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 3,
				xAxisIndex: 1,
				yAxisIndex: 1,
				z: 1,
			},{ 
				type: 'line', 
				name: 'Zona 3',
				stack: 'allMonths',
				lineStyle: {
					color: '#fe3a1a',
					width: 0.5
				},
				symbol: 'none',
				areaStyle: {
					color: '#ff9d8d',
				},
				label: {
					show: false,
				},
				connectNulls: false,
				datasetIndex: 3,
				xAxisIndex: 1,
				yAxisIndex: 1,
				z: 1,
			},{
				type: 'line',
				symbol: 'none',
				lineStyle: {
					width: 1,
					color: "#666",
					type: 'dashed',
				},
				itemStyle: {
					color: '#666',
				},
				datasetIndex: 3,
				xAxisIndex: 1,
				yAxisIndex: 3,
			},
		],
	};
	
	// Para resetar a legenda a cada add/rem de uma série
	trainingIntensityDistributionFull.setOption(option, true);
	
	window.addEventListener("resize", trainingIntensityDistributionFull.resize);
};

initTrainingIntensityDistributionFullChart(
	mainActivitiesWeekTid,
	mainActivitiesMonthTid,
	allActivitiesWeekTid,
	allActivitiesMonthTid,
	datasetDimensions,
);